import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

const ContactForm = () => {

  const [data, setData] = useState(null);
  const [statusText, setStatusText] = useState(null)

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setData({...data, [name]: value});
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    setStatusText("Envoi en cours...")

    executeRecaptcha("contact_page").then((token) => {
      return axios.post('/api/mail', {...data, token: token})
    })
    .then(function (response) {
      setStatusText(response.data.msg)
      setTimeout(() => {
        event.target.reset();
        setData(null);
        setStatusText(null);
      }, 5000);
    })
    .catch(function (error) {
      setStatusText(error.data?.msg ?? "Erreur lors de l'envoi")
    });
  }


  return (
    <div>
      <h3>Formulaire de contact</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-4">
          <input type="text" className="form-control" id="name" name="name" placeholder="Votre nom"
                 onChange={handleChange}/>
          <label htmlFor="name">Votre nom</label>
        </div>
        <div className="form-floating mb-5">
          <input type="email" className="form-control" id="email" name="email" placeholder="name@example.com"
                 onChange={handleChange}/>
          <label htmlFor="email">Votre email</label>
        </div>
        <div className="form-floating mb-5">
          <textarea className="form-control" placeholder="Votre message" id="message" name="message"
                    style={{height: "150px"}} onChange={handleChange}/>
          <label htmlFor="message">Message</label>
        </div>
        <button type="submit" className="btn btn-primary" title="Ce formulaire est protégé avec reCAPTCHA.">Envoyer</button>
        <span className="ms-3">{statusText}</span>
      </form>
    </div>
  );
}

export default ContactForm
