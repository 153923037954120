import React from "react";
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import "./leaflet-map.scss";

const LeafletMap = (props) => {

  return (
    <>
      {typeof window !== 'undefined' &&
        <MapContainer center={props.position} zoom={props.zoom} scrollWheelZoom={false}>
          <TileLayer
            url="https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles courtesy of <a href="http://www.openstreetmap.bzh/" target="_blank">Breton OpenStreetMap Team</a>'
          />
          {props.markerText !== "" &&
          <Marker position={props.position}>
            <Popup>{props.markerText}</Popup>
          </Marker>
          }
        </MapContainer>
      }
    </>
  );
}

LeafletMap.propTypes = {
  /** Latitude and Longitude of the map centre in an array, eg [51, -1] **/
  position: PropTypes.array,

  /** Initial zoom level for the map (default 13) **/
  zoom: PropTypes.number,

  /** If set, will display a marker, which when clicked will display this text **/
  markerText: PropTypes.string
}

LeafletMap.defaultProps = {
  position: [51, -1],
  zoom: 13,
  markerText: ""
}

export default LeafletMap
