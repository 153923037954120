import * as React from "react"
import { graphql } from "gatsby"

import ContactForm from "../components/contact-form";
import LeafletMap from "../components/leaflet-map"

import * as styles from "./contact.module.scss"

const ContactPage = ({data}) => {
  return (
    <div>
      <div className="container mt-5">
        <div dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
        <LeafletMap
          position={[48.13877, -1.95642]}
          zoom={14}
          markerText={"Hôtel Montfort Communauté"}
        />
      </div>
      <div className="container mt-5">
        <h1>Nous contacter</h1>
        <div className="row">
          <div className="col-md-6 col-lg-7 mb-5">
            <ContactForm/>
          </div>
          <div className={`col-md-6 col-lg-5 px-md-3 px-lg-4 px-xl-5 ${styles.contact}`}>
            <h3>Coordonnées</h3>
            <ul>
              <li>
                <span className="fas fa-map-marker "/>
                <strong>Adresse :</strong> Cercle Montfortais - Bagad Men Ru<br/>Mairie<br/>35 160 Montfort sur Meu
              </li>
              <li>
                <span className="fas fa-envelope"/>
                <strong>E-mail :</strong> contact@bagadmenru.bzh
              </li>
              <li>
                <span className="fab fa-facebook"/>
                <strong>Facebook : </strong> <a href="https://www.facebook.com/BagadMenRu">Bagad Men Ru - Montfort sur Meu</a>
              </li>
              <li>
                <span className="fab fa-instagram"/>
                <strong>Instagram : </strong> <a href="https://www.instagram.com/bagadmenru/">@bagadmenru</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/_pages/contact.md/"}) {
      id
      html
    }
  }
`


export default ContactPage
